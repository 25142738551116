<template>
  <div class="app-container" style="margin-bottom: 20px">
    <!-- -------万恶分割线------- -->
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="120px"
      
      >
     <el-row  >
      <el-form-item label="上传类型"   v-model="fileType"  prop="fileType">
          <el-radio-group  v-model="fileType" >
            <el-radio  v-model="fileType"  @change="change" label="1">图片</el-radio>
            <el-radio  v-model="fileType"   @change="change" label="2">视频</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item  
        label="上传图片" 
         v-model="formData.fileList"
              v-if="fileType == 1"
              prop="fileList"
              :rules="rules.fid">
          <el-upload
            :file-list="fileList"
          list-type="picture-card"
            action="http://up-z0.qiniup.com"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview"
             :on-remove="handleRemove"
          >
          <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="imageUrl" alt="" />
          </el-dialog>
        </el-form-item>
  
        <el-form-item label="上传视频"   v-model="formData.fileList"
              v-if="fileType == 2"
              prop="fileList"
              :rules="rules.fid">
          <el-upload
            class="upload-demo"
            ref="up"
            action="http://up-z0.qiniup.com"
            :on-preview="handlePreview"
            :show-file-list="true"
            accept=".mp4,.MP4"
           
            :limit="1"
            :on-success="handleAvatarSuccess0"
            :data="postData0"
            :before-upload="beforeAvatarUpload0"
          >
            <el-button type="primary">点击上传</el-button>
            <template #tip>
              <div class="el-upload__tip">只能上传mp4类型的文件</div>
            </template>
          </el-upload>
        </el-form-item>

        <el-form-item
          label="封面"
          v-model="formData.fileList"
             
          v-if="fileType == 2"
        >
          <el-upload
            class="avatar-uploader"
            action="http://up-z0.qiniup.com"
            :show-file-list="false"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess1"
            :data="postData1"
            :before-upload="beforeAvatarUpload1"
          >
            <div v-if="img" class="avatar">
              <img :src="img" width="50%" />
            </div>

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
     </el-row>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <div style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          <el-button class="el-icon-edit" size="small" type="primary" @click="goback"
            >返回</el-button
          >
        </el-col>
        <el-col :span="12" align="right">
          <el-button class="el-icon-edit" size="small"   type="primary" @click="addphotos"
            >添加图片</el-button>
          <el-button class="el-icon-edit" size="small"  type="primary" @click="addvideo"
            >添加视频</el-button>
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->
    <el-button   size="small" type="primary" @click="batchDelete" :disabled="albumFileIds.length === 0" style="margin-bottom:20px" >批量删除</el-button>
    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="tableData"
        class="emp_table"
        v-if="list"
        @selection-change="handleSelectionChange"
      >
      <el-table-column
      type="selection"
      width="55">
    </el-table-column>
        <el-table-column align="center" prop="fid" label="文件" width="200">
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image
                v-if="scope.row.fileType == 1 && !scope.row.posterId"
                style="width: 35%"
                :src="'http://file.innopinenut.com/' + scope.row.fid"
                :preview-src-list="['http://file.innopinenut.com/' + scope.row.fid]"
              />
              <el-image
                v-else-if="scope.row.fileType == 2 && !scope.row.posterId"
                style="width: 35%"
                :src="
                  'http://file.innopinenut.com/' +
                  scope.row.fid +
                  '?vframe/jpg/offset/1'
                "
                :preview-src-list="[
                  'http://file.innopinenut.com/' +
                    scope.row.fid +
                    '?vframe/jpg/offset/1'
                ]"
              />
              <el-image
                v-else-if="scope.row.fileType == 2 && scope.row.posterId"
                style="width: 35%"
                :src="'http://file.innopinenut.com/' + scope.row.posterId"
                :preview-src-list="[
                  'http://file.innopinenut.com/' + scope.row.posterId
                ]"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="fileType"
          label="上传类型"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.fileType == 1 ? '图片' : '视频' }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="del(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getAlbumFileList"
      />
    </div>
  </div>
</template>
  <script>

import {
  addAlbumFile,
  getAlbumFileList,
  delAlbumFile,
  getUploadToken
} from '../../request/http'

export default {
  data() {
    return {
      editOradd:null,
      imgList: [], //多张照片回显
      fileList: [],
      list: '', //列表
      total: 0, //总数
      imageUrl: '',
      img: '',
      dialogVisible: false,
      postData: {
        token: '',
        key: '' //上传的Logo
      },

      postData0: {
        token: '',
        key: ''
      },
      postData1: {
        token: '',
        key: '' //上传的Logo
      },
      albumId: '',
      queryParams: {
        page: 1,
        pageSize: 10
      },
      title: '',
      showDialog: false,
    
      fileType:null,
      formData: {
        albumId: '',
        fileList: [
        ]
      },
      albumFileIds:[],
      rules: {
        // posterId: [
        //   { required: true, message: "封面不能为空", trigger: "blur" },
        // ],
        fileType: [
          { required: true, message: '上传类型不能为空', trigger: 'blur' }
        ],
        fid: [{ required: true, message: '文件不能为空', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.albumId = this.$route.query.albumId
    this.formData.albumId = this.$route.query.albumId
    console.log(this.formData.albumId)

    for (let i = 0; i < this.formData.fileList.length; i++) {
        this.fileList.push({
          url: "http://file.innopinenut.com/" +  this.formData.fileList[i].fid,
          key: this.formData.fileList[i].fid,
        });
      }
  },
  mounted() {
    this.getAlbumFileList()
  },

  methods: {
    change(val) {
      if(this.fileType==1&&val==2|| this.fileType==2&&val==1){
        this.formData =  {
        albumId: this.albumId,
        fileList: [
        ]
      }
        }
        if(val==1){
       
       this.fileType=val
     }
     else if (val==2) {
         this.fileType=val
         this.formData.fileList[0]={
           fileType:val,
           fid:"",
           posterId:''

         }
   
     }
     
     console.log(this.fileType);
      
    },
    //选择
    handleSelectionChange(val) {
     this.albumFileIds =val
      },
      // 批量删除
batchDelete () {
   let deleteData = this.albumFileIds.map(item => {
       const albumFileId = item.albumFileId
       return albumFileId
   })
   console.log(deleteData);
   this.deleteApi(deleteData)
},

deleteApi(data) {
  this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await delAlbumFile({ albumFileIds: data })
          // console.log(res);
          res.code == 0 &&
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          const totalPage = Math.ceil(
            (this.total - 1) / this.queryParams.pageSize
          )
          this.queryParams.page =
            this.queryParams.page > totalPage
              ? totalPage
              : this.queryParams.page
          this.queryParams.page =
            this.queryParams.page < 1 ? 1 : this.queryParams.page
          this.getAlbumFileList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
},
















    async getAlbumFileList() {
      let { data, count } = await getAlbumFileList({
        albumId: this.albumId
      })
      this.list = data
      this.total = count
    },

    handlePreview(file) {
      if (this.formData.fileType == 2 && this.formData.fid) {
        window.open('http://file.innopinenut.com/' + this.formData.fid, '_blank')
      } else {
        this.$message.warning('暂不支持预览')
      }
    },
    // 上传图片方法
    handleAvatarSuccess(res) {
         this.formData.fileList.push({
            fileType:1,
            fid:res.key
        })
        console.log(this.formData.fileList);
      
    },
    async beforeAvatarUpload(file) {
       
   
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传时获取Token失败！')
        return
      }
      this.postData.token = data.token
      this.postData.key = data.fid
    },
    // 上传方法
    handleAvatarSuccess0(res, file) {
      console.log('上传成功', res, file)
      this.formData.fileList[0].fid = res.key
    },
    async beforeAvatarUpload0(file) {
    
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传文件时获取Token失败！')
        return
      }
      this.postData0.token = data.token
      this.postData0.key = data.fid
    },

    // 上传图片方法
    handleAvatarSuccess1(res, file) {
      console.log('上传成功', res, file)

    
        this.img = 'http://file.innopinenut.com/' + res.key
        this.formData.fileList[0].posterId = res.key
      
    },
    async beforeAvatarUpload1(file) {
      // console.log("上传前");
      let { data } = await getUploadToken()
      // console.log(data);
      if (data == '' || data == null) {
        this.message.error('上传时获取Token失败！')
        return
      }
      this.postData1.token = data.token
      this.postData1.key = data.fid
    },

      //删除多张图片的一张
      handleRemove(file) {
      let key;
      if (this.editOradd) {
        key = file.key;
      } else {
        key = file.response.key;
      }
       
      let i = this.formData.fileList.indexOf(key);
      console.log(key,i);
      // console.log(i);
      this.formData.fileList= this.formData.fileList.filter(item => item.fid!==key)
      console.log(this.formData.fileList);
    
    },
    //点击放大，查看图片
    handlePictureCardPreview(file) {
      console.log(file);
      window.open(file.url, '_blank')
   
    },
    // 打开添加的窗口

    addphotos() {
    
      this.editOradd = 0;
      let editOradd = this.editOradd;
      let albumId= this.albumId
      this.$router.push({
        path: "addphotos",
        query: { editOradd,albumId },
      });
    },
    addvideo() {
    this.editOradd = 0;
    let editOradd = this.editOradd;
    let albumId= this.albumId
    this.$router.push({
      path: "addvideo",
      query: { editOradd,albumId },
    });


  },
    // 删除类别
    del(val) {
      console.log(val)
      let id = val.albumFileId
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await delAlbumFile({ albumFileIds: [id] })
          // console.log(res);
          res.code == 0 &&
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          const totalPage = Math.ceil(
            (this.total - 1) / this.queryParams.pageSize
          )
          this.queryParams.page =
            this.queryParams.page > totalPage
              ? totalPage
              : this.queryParams.page
          this.queryParams.page =
            this.queryParams.page < 1 ? 1 : this.queryParams.page
          this.getAlbumFileList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 返回上一级页面
    goback() {
      this.$router.go(-1)
    },
    // 确认
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {
      
      
        let { code, msg } = await addAlbumFile(this.formData)
        console.log(code, msg)
        if (code == 0) {
          this.$message.success('新增成功')
        } else {
          console.log(msg)
          this.$message({
            type: 'error',
            message: msg
          })
        }
        this.getAlbumFileList()
        this.showDialog = false
      })
    },

    //弹窗取消函数
    btnCancel() {
      this.img = ''
      this.fileList=[]
      this.fileType=""
     this.formData =  {
        albumId: this.albumId,
        fileList: [
        ]
      },

      this.$refs.perForm.resetFields()
      this.showDialog = false
    }
  }
}
</script>
  
  <style>
</style>